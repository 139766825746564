import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { Link, useParams } from 'react-router-dom';
import { IoIosArrowForward } from 'react-icons/io';
import { Range } from 'react-range';
import { AiFillStar } from 'react-icons/ai';
import { CiStar } from 'react-icons/ci';
import Products from '../components/products/Products';
import { BsFillGridFill } from 'react-icons/bs';
import { FaThList } from 'react-icons/fa';
import ShopProducts from '../components/products/ShopProducts';
import Pagination from '../components/Pagination';
import { useDispatch, useSelector } from 'react-redux';
import cookies from 'js-cookie';
import {
  price_range_product,
  query_shop_products,
} from '../store/reducers/homeReducer';

const ProductsList = () => {
  const { slugShop } = useParams();

  const dispatch = useDispatch();

  const {
    products = [],
    // categorys,
    priceRange,
    latest_product,
    totalProduct,
    parPage,
  } = useSelector((state) => state.home);

  const [currentLanguageCode, setCurrentLanguageCode] = useState(
    cookies.get('i18next') || 'en'
  );

  useEffect(() => {
    dispatch(price_range_product());
  }, [dispatch, slugShop]);

  useEffect(() => {
    setState({
      values: [priceRange.low, priceRange.high],
    });

    setCurrentLanguageCode(cookies.get('i18next') || 'en');
  }, [priceRange, slugShop]);

  const [filter, setFilter] = useState(true);

  const [state, setState] = useState({
    values: [priceRange.low, priceRange.high],
  });
  const [rating, setRating] = useState('');
  const [styles, setStyles] = useState('grid');

  const [pageNumber, setPageNumber] = useState(1);

  const [sortPrice, setSortPrice] = useState('');
  // const [category, setCategory] = useState('');
  // const queryCategory = (e, value) => {
  //   if (e.target.checked) {
  //     setCategory(value);
  //   } else {
  //     setCategory('');
  //   }
  // };

  useEffect(() => {
    dispatch(
      query_shop_products({
        low: state.values[0],
        high: state.values[1],
        // category,
        slugShop,
        rating,
        sortPrice,
        pageNumber,
      })
    );
  }, [
    state.values[0],
    state.values[1],
    // category,

    slugShop,
    rating,
    sortPrice,
    pageNumber,
  ]);

  const resetRating = () => {
    setRating('');
    dispatch(
      query_shop_products({
        low: state.values[0],
        high: state.values[1],
        // category,
        slugShop,
        rating: '',
        sortPrice,
        pageNumber,
      })
    );
  };

  return (
    <div>
      <Header />
      <section className=" mt-8 bg-cover bg-no-repeat relative bg-left">
        <div className="absolute left-0 top-0 w-full h-full bg-[#2422228a]">
          <div className="w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto">
            <div className="flex flex-col justify-center gap-1 items-center h-full w-full text-white">
              {/* <h2 className="text-3xl font-bold">Shop Page </h2>
              <div className="flex justify-center items-center gap-2 text-2xl w-full">
                <Link to="/">Home</Link>
                <span className="pt-1">
                  <IoIosArrowForward />
                </span>
                <span>Shop </span>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className=" bg-[#F8F9F9] py-5 mb-5">
          <div className="w-[70%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto">
            <div className="flex justify-start items-center text-md text-slate-600 w-full">
              <Link to="/">Home</Link>
              <span>
                <IoIosArrowForward />
              </span>
              <Link to="/shops">{slugShop}</Link>
              <span>
                <IoIosArrowForward />
              </span>
              <span className="text-[#B65278]">Products </span>
            </div>
          </div>
        </div>
      </section>

      <section className="py-6">
        <div className="w-[68%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto">
          <div className={` md:block hidden ${!filter ? 'mb-6' : 'mb-0'} `}>
            <button
              onClick={() => setFilter(!filter)}
              className="text-center w-full py-2 px-3 bg-[#2A629A] text-white"
            >
              Filter Product
            </button>
          </div>

          <div className="w-full flex flex-wrap ">
            <div
              className={`w-3/12 md-lg:w-4/12 md:w-full  p-8 ${
                filter
                  ? 'md:h-0 md:overflow-hidden md:mb-6'
                  : 'md:h-auto md:overflow-auto md:mb-0'
              } `}
            >
              {/* <h2 className="text-3xl font-bold mb-3 text-slate-600">
                Category{' '}
              </h2> */}
              {/* <div className="py-2 ">
                {categorys.map((c, i) => (
                  <div
                    key={i}
                    className="flex justify-start items-center gap-2 py-1"
                  >
                    <input
                      checked={category === c.name ? true : false}
                      onChange={(e) => queryCategory(e, c.name)}
                      type="checkbox"
                      id={c.name}
                    />
                    <label
                      className="text-slate-600 block cursor-pointer"
                      htmlFor={c.name}
                    >
                      {c.name}
                    </label>
                  </div>
                ))}
              </div> */}

              <div className="py-2 flex flex-col gap-5">
                <h2 className="text-3xl font-bold mb-3 text-slate-600">
                  Price
                </h2>

                <Range
                  rtl={currentLanguageCode === 'en' ? false : true}
                  step={5}
                  min={priceRange.low}
                  max={priceRange.high}
                  values={state.values}
                  onChange={(values) => setState({ values })}
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      className={`w-full h-[6px] bg-slate-200 rounded-full cursor-pointer ${
                        currentLanguageCode === 'en'
                          ? 'ltr:left-0'
                          : 'rtl:right-0'
                      }`}
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      className="w-[15px] h-[15px] bg-[#059473] rounded-full"
                      {...props}
                    />
                  )}
                />
                <div>
                  <span className="text-slate-800 font-bold text-lg">
                    ${Math.floor(state.values[0])} - $
                    {Math.floor(state.values[1])}
                  </span>
                </div>
              </div>

              <div className="py-3 flex flex-col gap-4">
                <h2 className="text-3xl font-bold mb-3 text-slate-600">
                  Rating{' '}
                </h2>
                <div className="flex flex-col gap-3">
                  <div
                    onClick={() => setRating(5)}
                    className="text-orange-500 flex justify-start items-start gap-2 text-xl cursor-pointer"
                  >
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <AiFillStar />{' '}
                    </span>
                  </div>

                  <div
                    onClick={() => setRating(4)}
                    className="text-orange-500 flex justify-start items-start gap-2 text-xl cursor-pointer"
                  >
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                  </div>

                  <div
                    onClick={() => setRating(3)}
                    className="text-orange-500 flex justify-start items-start gap-2 text-xl cursor-pointer"
                  >
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                  </div>

                  <div
                    onClick={() => setRating(2)}
                    className="text-orange-500 flex justify-start items-start gap-2 text-xl cursor-pointer"
                  >
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                  </div>

                  <div
                    onClick={() => setRating(1)}
                    className="text-orange-500 flex justify-start items-start gap-2 text-xl cursor-pointer"
                  >
                    <span>
                      <AiFillStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                  </div>

                  <div
                    onClick={resetRating}
                    className="text-orange-500 flex justify-start items-start gap-2 text-xl cursor-pointer"
                  >
                    <span>
                      <CiStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                    <span>
                      <CiStar />{' '}
                    </span>
                  </div>
                </div>
              </div>

              <div className="py-5 flex flex-col gap-4 md:hidden">
                <Products title="Latest Product" products={latest_product} />
              </div>
            </div>

            <div className="w-9/12 md-lg:w-8/12 md:w-full">
              <div className="pl-8 md:pl-0">
                <div className="py-4 bg-white mb-10 px-3 rounded-md flex justify-between items-start border">
                  <h2 className="text-lg font-medium text-slate-600">
                    {' '}
                    ({totalProduct}) Products{' '}
                  </h2>
                  <div className="flex justify-center items-center gap-3">
                    <select
                      onChange={(e) => setSortPrice(e.target.value)}
                      className="p-1 border outline-0 text-slate-600 font-semibold"
                      name=""
                      id=""
                    >
                      <option value="">Sort By</option>
                      <option value="low-to-high">Low to High Price</option>
                      <option value="high-to-low">High to Low Price </option>
                    </select>
                    <div className="flex justify-center items-start gap-4 md-lg:hidden">
                      <div
                        onClick={() => setStyles('grid')}
                        className={`p-2 ${
                          styles === 'grid' && 'bg-slate-300'
                        } text-slate-600 hover:bg-slate-300 cursor-pointer rounded-sm `}
                      >
                        <BsFillGridFill />
                      </div>
                      <div
                        onClick={() => setStyles('list')}
                        className={`p-2 ${
                          styles === 'list' && 'bg-slate-300'
                        } text-slate-600 hover:bg-slate-300 cursor-pointer rounded-sm `}
                      >
                        <FaThList />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pb-8">
                  <ShopProducts products={products} styles={styles} />
                </div>

                <div>
                  {totalProduct > parPage && (
                    <Pagination
                      pageNumber={pageNumber}
                      setPageNumber={setPageNumber}
                      totalItem={totalProduct}
                      parPage={parPage}
                      showItem={Math.floor(totalProduct / parPage)}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ProductsList;

// import React, { useState, useEffect, useMemo, Suspense } from 'react';
// import { Link, useParams } from 'react-router-dom';
// import { IoIosArrowForward } from 'react-icons/io';
// import { Range } from 'react-range';
// import { AiFillStar } from 'react-icons/ai';
// import { CiStar } from 'react-icons/ci';
// import { BsFillGridFill } from 'react-icons/bs';
// import { FaThList } from 'react-icons/fa';
// import { useDispatch, useSelector } from 'react-redux';
// import cookies from 'js-cookie';
// import {
//   price_range_product,
//   query_shop_products,
// } from '../store/reducers/homeReducer';

// const Header = React.lazy(() => import('../components/Header'));
// const Footer = React.lazy(() => import('../components/Footer'));
// const ShopProducts = React.lazy(() => import('../components/products/ShopProducts'));
// const Pagination = React.lazy(() => import('../components/Pagination'));
// const Products = React.lazy(() => import('../components/products/Products'));

// const ProductsList = () => {
//   const { slugShop } = useParams();
//   const dispatch = useDispatch();
//   const {
//     products = [], // Initialize products as an empty array
//     priceRange = { low: 0, high: 1000 }, // Initialize priceRange with default values
//     latest_product = [], // Initialize latest_product as an empty array
//     totalProduct = 0, // Initialize totalProduct as 0
//     parPage = 10, // Initialize parPage with a default value
//   } = useSelector((state) => state.home);

//   const [currentLanguageCode, setCurrentLanguageCode] = useState(
//     cookies.get('i18next') || 'en'
//   );

//   useEffect(() => {
//     dispatch(price_range_product());
//   }, [currentLanguageCode, dispatch]);

//   useEffect(() => {
//     setState({
//       values: [priceRange.low, priceRange.high],
//     });

//     setCurrentLanguageCode(cookies.get('i18next') || 'en');
//   }, [priceRange, slugShop, currentLanguageCode]);

//   const [filter, setFilter] = useState(true);
//   const [state, setState] = useState({
//     values: [priceRange.low, priceRange.high],
//   });
//   const [rating, setRating] = useState('');
//   const [styles, setStyles] = useState('grid');
//   const [pageNumber, setPageNumber] = useState(1);
//   const [sortPrice, setSortPrice] = useState('');

//   const fetchProducts = useMemo(() => {
//     return () => {
//       dispatch(
//         query_shop_products({
//           low: state.values[0],
//           high: state.values[1],
//           slugShop,
//           rating,
//           sortPrice,
//           pageNumber,
//         })
//       );
//     };
//   }, [state.values, slugShop, rating, sortPrice, pageNumber, dispatch]);

//   useEffect(() => {
//     fetchProducts();
//   }, [fetchProducts]);

//   const resetRating = () => {
//     setRating('');
//     fetchProducts();
//   };

//   return (
//     <div>
//       <Suspense fallback={<div>Loading...</div>}>
//         <Header />
//       </Suspense>
//       <section className=" mt-8 bg-cover bg-no-repeat relative bg-left">
//         <div className="absolute left-0 top-0 w-full h-full bg-[#2422228a]">
//           <div className="w-[85%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto">
//             <div className="flex flex-col justify-center gap-1 items-center h-full w-full text-white">
//               {/* Header Content */}
//             </div>
//           </div>
//         </div>
//       </section>
//       <section>
//         <div className=" bg-[#F8F9F9] py-5 mb-5">
//           <div className="w-[70%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto">
//             <div className="flex justify-start items-center text-md text-slate-600 w-full">
//               <Link to="/">Home</Link>
//               <span>
//                 <IoIosArrowForward />
//               </span>
//               <Link to="/shops">{slugShop}</Link>
//               <span>
//                 <IoIosArrowForward />
//               </span>
//               <span className="text-[#B65278]">Products </span>
//             </div>
//           </div>
//         </div>
//       </section>

//       <section className="py-6">
//         <div className="w-[68%] md:w-[80%] sm:w-[90%] lg:w-[90%] h-full mx-auto">
//           <div className={` md:block hidden ${!filter ? 'mb-6' : 'mb-0'} `}>
//             <button
//               onClick={() => setFilter(!filter)}
//               className="text-center w-full py-2 px-3 bg-[#2A629A] text-white"
//             >
//               Filter Product
//             </button>
//           </div>

//           <div className="w-full flex flex-wrap ">
//             <div
//               className={`w-3/12 md-lg:w-4/12 md:w-full  p-8 ${
//                 filter
//                   ? 'md:h-0 md:overflow-hidden md:mb-6'
//                   : 'md:h-auto md:overflow-auto md:mb-0'
//               } `}
//             >
//               {/* Price Range and Rating Filter */}
//               <div className="py-2 flex flex-col gap-5">
//                 <h2 className="text-3xl font-bold mb-3 text-slate-600">
//                   Price
//                 </h2>

//                 <Range
//                   rtl={currentLanguageCode === 'en' ? false : true}
//                   step={5}
//                   min={priceRange.low}
//                   max={priceRange.high}
//                   values={state.values}
//                   onChange={(values) => setState({ values })}
//                   renderTrack={({ props, children }) => (
//                     <div
//                       {...props}
//                       className={`w-full h-[6px] bg-slate-200 rounded-full cursor-pointer ${
//                         currentLanguageCode === 'en'
//                           ? 'ltr:left-0'
//                           : 'rtl:right-0'
//                       }`}
//                     >
//                       {children}
//                     </div>
//                   )}
//                   renderThumb={({ props }) => (
//                     <div
//                       className="w-[15px] h-[15px] bg-[#059473] rounded-full"
//                       {...props}
//                     />
//                   )}
//                 />
//                 <div>
//                   <span className="text-slate-800 font-bold text-lg">
//                     ${Math.floor(state.values[0])} - $
//                     {Math.floor(state.values[1])}
//                   </span>
//                 </div>
//               </div>

//               <div className="py-3 flex flex-col gap-4">
//                 <h2 className="text-3xl font-bold mb-3 text-slate-600">
//                   Rating{' '}
//                 </h2>
//                 <div className="flex flex-col gap-3">
//                   {/* Ratings */}
//                   {[5, 4, 3, 2, 1].map((rate) => (
//                     <div
//                       key={rate}
//                       onClick={() => setRating(rate)}
//                       className="text-orange-500 flex justify-start items-start gap-2 text-xl cursor-pointer"
//                     >
//                       {[...Array(rate)].map((_, i) => (
//                         <AiFillStar key={i} />
//                       ))}
//                       {[...Array(5 - rate)].map((_, i) => (
//                         <CiStar key={i} />
//                       ))}
//                     </div>
//                   ))}

//                   <div
//                     onClick={resetRating}
//                     className="text-orange-500 flex justify-start items-start gap-2 text-xl cursor-pointer"
//                   >
//                     {[...Array(5)].map((_, i) => (
//                       <CiStar key={i} />
//                     ))}
//                   </div>
//                 </div>
//               </div>

//               <div className="py-5 flex flex-col gap-4 md:hidden">
//                 <Suspense fallback={<div>Loading...</div>}>
//                   <Products title="Latest Product" products={latest_product} />
//                 </Suspense>
//               </div>
//             </div>

//             <div className="w-9/12 md-lg:w-8/12 md:w-full">
//               <div className="pl-8 md:pl-0">
//                 <div className="py-4 bg-white mb-10 px-3 rounded-md flex justify-between items-start border">
//                   <h2 className="text-lg font-medium text-slate-600">
//                     ({totalProduct}) Products
//                   </h2>
//                   <div className="flex justify-center items-center gap-3">
//                     <select
//                       onChange={(e) => setSortPrice(e.target.value)}
//                       className="border outline-none px-3 py-2 rounded-md cursor-pointer"
//                     >
//                       <option value="">Select</option>
//                       <option value="highToLow">Price high to low</option>
//                       <option value="lowToHigh">Price low to high</option>
//                     </select>

//                     <div className="flex justify-center items-start gap-4 md-lg:hidden">
//                       <div
//                         onClick={() => setStyles('grid')}
//                         className={`p-2 ${
//                           styles === 'grid' && 'bg-slate-300'
//                         } text-slate-600 hover:bg-slate-300 cursor-pointer rounded-sm `}
//                       >
//                         <BsFillGridFill />
//                       </div>
//                       <div
//                         onClick={() => setStyles('list')}
//                         className={`p-2 ${
//                           styles === 'list' && 'bg-slate-300'
//                         } text-slate-600 hover:bg-slate-300 cursor-pointer rounded-sm `}
//                       >
//                         <FaThList />
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="pb-8">
//                   <Suspense fallback={<div>Loading...</div>}>
//                     <ShopProducts products={products} styles={styles} />
//                   </Suspense>
//                 </div>

//                 <div>
//                   {totalProduct > parPage && (
//                     <Suspense fallback={<div>Loading...</div>}>
//                       <Pagination
//                         pageNumber={pageNumber}
//                         setPageNumber={setPageNumber}
//                         totalItem={totalProduct}
//                         parPage={parPage}
//                         showItem={Math.floor(totalProduct / parPage)}
//                       />
//                     </Suspense>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>

//       <Suspense fallback={<div>Loading...</div>}>
//         <Footer />
//       </Suspense>
//     </div>
//   );
// };

// export default React.memo(ProductsList);
