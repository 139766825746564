import React from 'react';
// import { FaEye, FaRegHeart } from 'react-icons/fa';
// import { RiShoppingCartLine } from 'react-icons/ri';
// import Rating from '../Rating';
import { backend_url_img } from '../api/server';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram } from 'react-icons/fa';
// import toast from 'react-hot-toast';
// import { useDispatch, useSelector } from 'react-redux';
// import {
//   add_to_card,
//   add_to_wishlist,
//   messageClear,
// } from '../../store/reducers/cardReducer';

const ShopList = ({ styles, sellers }) => {
  // const dispatch = useDispatch();
  // const navigate = useNavigate();
  // const { userInfo } = useSelector((state) => state.auth);
  // const { errorMessage, successMessage } = useSelector((state) => state.card);

  // const add_card = (id) => {
  // if (userInfo) {
  //   dispatch(
  //     add_to_card({
  //       userId: userInfo.id,
  //       quantity: 1,
  //       productId: id,
  //     })
  //   );
  // } else {
  //   navigate('/login');
  // }
  // };

  // const add_wishlist = (pro) => {
  // dispatch(
  //   add_to_wishlist({
  //     userId: userInfo.id,
  //     productId: pro._id,
  //     name: pro.name,
  //     price: pro.price,
  //     image: pro.images[0],
  //     discount: pro.discount,
  //     rating: pro.rating,
  //     slug: pro.slug,
  //   })
  // );
  // };

  // useEffect(() => {
  //   if (successMessage) {
  //     toast.success(successMessage);
  //     dispatch(messageClear());
  //   }
  //   if (errorMessage) {
  //     toast.error(errorMessage);
  //     dispatch(messageClear());
  //   }
  // }, [successMessage, errorMessage]);

  return (
    <div
      className={`w-full grid ${
        styles === 'grid'
          ? 'grid-cols-4 md-lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2'
          : 'grid-cols-1 md-lg:grid-cols-2 md:grid-cols-2'
      } gap-4 sm:gap-3`}
    >
      {sellers && sellers?.map((p, i) => (
        <div
          key={i}
          // class="relative flex w-full max-w-[26rem] flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-lg"
          className={`flex border relative  w-full  rounded-xl  transition-all duration-1000 hover:shadow-md hover:-translate-y-1 ${
            styles === 'grid'
              ? 'flex-col justify-start items-start'
              : 'justify-start items-center md-lg:flex-col md-lg:justify-start md-lg:items-start'
          } w-full gap-4 bg-white  rounded-md`}
        >
          <div
            className={
              styles === 'grid'
                ? 'w-full relative group h-auto  overflow-hidden'
                : 'md-lg:w-full relative  group h-[180px] md:h-[220px] sm:h-[280px] overflow-hidden'
            }
            // class="relative mx-2 mt-2 overflow-hidden text-white shadow-lg rounded-xl bg-blue-gray-500 bg-clip-border shadow-blue-gray-500/40"
          >
            <Link to={`/shop/products/${p.slug}`}>
              <img
                className="h-[180px] md:h-[160px] sm:h-[140px] rounded-md w-full "
                src={`${backend_url_img}/uploads/${p.image}`}
                alt={p.image}
              />
            </Link>
          </div>
          <div class="p-2">
            <div class="flex items-center justify-between mb-2">
              <h5 class="block font-sans text-xl antialiased font-medium leading-snug tracking-normal text-blue-gray-900">
                {p.shopInfo?.shopName}
              </h5>
            </div>

            <div className="flex justify-end">
              {p.shopInfo?.address}/{p.shopInfo?.city}
            </div>
            <div class="flex justify-between items-center gap-2 mt-2 group">
              <div class="flex items-center gap-2 mt-2 group">
                <span class="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-2 text-gray-900 transition-colors hover:bg-[#B65278] hover:text-white hover:!opacity-100 group-hover:opacity-70">
                  <FaFacebookF />{' '}
                </span>
                <span class="cursor-pointer rounded-full border border-gray-900/5 bg-gray-900/5 p-2 text-gray-900 transition-colors hover:bg-[#B65278] hover:text-white hover:!opacity-100 group-hover:opacity-70">
                  <FaInstagram />{' '}
                </span>
              </div>
              <div>
                <p class="font-sans text-base antialiased font-light leading-relaxed text-gray-700">
                  {p.phone}
                </p>
              </div>
            </div>
          </div>
          {/* <div class="p-6 pt-3">
          <button
            class="block w-full select-none rounded-lg bg-gray-900 py-3.5 px-7 text-center align-middle font-sans text-sm font-bold uppercase text-white shadow-md shadow-gray-900/10 transition-all hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
            type="button">
            Reserve
          </button>
        </div> */}
        </div>
        // <div
        //   key={i}
        //   className={`flex border  transition-all duration-1000 hover:shadow-md hover:-translate-y-1 ${
        //     styles === 'grid'
        //       ? 'flex-col justify-start items-start sm:h-[240px] md:h-[280px]'
        //       : 'justify-start items-center md-lg:flex-col md-lg:justify-start md-lg:items-start'
        //   } w-full gap-4 bg-white  rounded-md`}
        // >
        //   <div
        //     className={
        //       styles === 'grid'
        //         ? 'w-full relative group h-auto  overflow-hidden'
        //         : 'md-lg:w-full relative group h-[210px] md:h-[260px] sm:h-[180px] overflow-hidden'
        //     }
        //   >
        //     <Link to={`/shop/products/${p.slug}`}>
        //       <img
        //         className="h-[210px] md:h-[140px] sm:h-[120px] rounded-md w-full "
        //         src={`${backend_url_img}/uploads/${p.image}`}
        //         alt=""
        //       />
        //     </Link>

        //   </div>

        //   <div className="flex justify-start items-start flex-col gap-1 p-2">
        //     <h2 className="font-bold">{p.shopInfo?.shopName}</h2>
        //     <div className="flex justify-start items-center gap-3">
        //       <span className="text-md font-semibold">{p.phone}</span>
        //     </div>
        //     <div className="flex justify-end">
        //       {p.shopInfo?.address}/{p.shopInfo?.city}
        //     </div>
        //   </div>
        // </div>
      ))}
    </div>
  );
};

export default ShopList;
